export const Loader = () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const LhHeader = () => import('../../components/lh-header.vue' /* webpackChunkName: "components/lh-header" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsBenchmarking = () => import('../../components/analytics/benchmarking.vue' /* webpackChunkName: "components/analytics-benchmarking" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsChart = () => import('../../components/analytics/chart.vue' /* webpackChunkName: "components/analytics-chart" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsCircleProgressBar = () => import('../../components/analytics/circle-progress-bar.vue' /* webpackChunkName: "components/analytics-circle-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsDetailsPopup = () => import('../../components/analytics/details-popup.vue' /* webpackChunkName: "components/analytics-details-popup" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsHeader = () => import('../../components/analytics/header.vue' /* webpackChunkName: "components/analytics-header" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsMetrics = () => import('../../components/analytics/metrics.vue' /* webpackChunkName: "components/analytics-metrics" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsPlatform = () => import('../../components/analytics/platform.vue' /* webpackChunkName: "components/analytics-platform" */).then(c => wrapFunctional(c.default || c))
export const IndexLightbench = () => import('../../components/index/Lightbench.vue' /* webpackChunkName: "components/index-lightbench" */).then(c => wrapFunctional(c.default || c))
export const IndexImportPopup = () => import('../../components/index/import-popup.vue' /* webpackChunkName: "components/index-import-popup" */).then(c => wrapFunctional(c.default || c))
export const IndexPlatformList = () => import('../../components/index/platform-list.vue' /* webpackChunkName: "components/index-platform-list" */).then(c => wrapFunctional(c.default || c))
export const IndexProfileList = () => import('../../components/index/profile-list.vue' /* webpackChunkName: "components/index-profile-list" */).then(c => wrapFunctional(c.default || c))
export const IndexProfilesPopup = () => import('../../components/index/profiles-popup.vue' /* webpackChunkName: "components/index-profiles-popup" */).then(c => wrapFunctional(c.default || c))
export const IndexSettingsPopup = () => import('../../components/index/settings-popup.vue' /* webpackChunkName: "components/index-settings-popup" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
